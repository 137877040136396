import axios from "axios";

const api = axios.create({
 baseURL: "https://act-api.salesbreath.com.br/api/v1",

 //baseURL: "http://acthomologacao.salesbreath.com.br:3010/api/v1/",
//  baseURL: "http://localhost:3010/api/v1/",
  headers: { "Content-Type": "application/json" }
});

export default api;
